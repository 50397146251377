import React, { useState } from "react";

import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  ItemText,
  SeeMore,
  StyledBox,
  StyledListItem,
  ProductsContainer,
} from "../../styled/DoneProductsList";
import { FreeProducts } from "../../styled/ProductQtyControlCard";

const DoneProductList = ({ data = "", done, text = "done" }) => {
  const [showAllProducts, setShowAllProducts] = useState(true);
  return (
    <StyledBox is_done={`${done}`}>
      <h4 style={{ marginBottom: "0.75rem" }}>
        {done ? text : "un" + text} Products List
      </h4>
      <ProductsContainer expand={`${showAllProducts}`}>
        {data?.length > 0 &&
          data.map((item, index) => (
            <div>
              <StyledListItem
                key={index}
                showBorder={`${item?.get_y_products?.length > 0}`}
              >
                <figure>
                  <img
                    src={item?.sku?.product?.media[0]?.thumbnail}
                    alt={item?.sku?.product?.name}
                  />
                </figure>
                <ItemText>
                  <h6 className="name">{item.sku?.product?.name}</h6>
                  <p className="qty">Requested Quantity : {item?.quantity}</p>
                  <div className="variations">
                    {item?.sku?.variations?.map((vari) => {
                      const color = vari?.variation?.type_value?.startsWith("#")
                        ? vari?.variation?.type_value
                        : null;
                      if (color)
                        return (
                          <p
                            key={vari.id}
                            className="color"
                            style={{ backgroundColor: color }}
                          ></p>
                        );
                      else
                        return (
                          <p
                            key={vari.id}
                            className="variation"
                            style={{ backgroundColor: "#eee" }}
                          >
                            {vari?.variation.name}
                          </p>
                        );
                    })}
                  </div>
                </ItemText>
              </StyledListItem>
              {item?.get_y_products && item?.get_y_products?.length > 0 && (
                <FreeProducts>
                  <h4>Gifts</h4>
                  <div>
                    {item?.get_y_products?.map((prod) => (
                      <div key={prod.id} className="item">
                        <figure>
                          <img src={prod?.image} alt={prod?.name} />
                        </figure>
                        <ItemText>
                          <h6 className="name">{prod?.name}</h6>
                          <div className="variations">
                            {prod?.variations?.map((vari) => {
                              if (vari?.type_value === "default") return "";
                              const color = vari?.type_value?.startsWith("#")
                                ? vari?.type_value
                                : null;
                              if (color)
                                return (
                                  <p
                                    key={vari.id}
                                    className="color"
                                    style={{ backgroundColor: color }}
                                  ></p>
                                );
                              else
                                return (
                                  <p
                                    key={vari.id}
                                    className="variation"
                                    style={{ backgroundColor: "#eee" }}
                                  >
                                    {vari?.name}
                                  </p>
                                );
                            })}
                          </div>
                        </ItemText>
                      </div>
                    ))}
                  </div>
                </FreeProducts>
              )}
            </div>
          ))}
      </ProductsContainer>
      {/* {data?.length > 3 && (
      <SeeMore
        isDone={`${done}`}
        onClick={() => setShowAllProducts(!showAllProducts)}
        endIcon={
          showAllProducts ? (
            <ArrowUpIcon
              sx={{ transform: "scale(1.5)", marginLeft: "-1px" }}
            />
          ) : (
            <ArrowDownIcon
              sx={{ transform: "scale(1.5)", margin: "-3px 0 0 -3px" }}
            />
          )
        }
      >
        {showAllProducts ? "See Less" : "See More"}
      </SeeMore>
    )} */}
    </StyledBox>
  );
};

export default DoneProductList;
