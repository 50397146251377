import React, { useState } from "react";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useSelector } from "react-redux";

import { Box, Divider } from "@mui/material";
import {
  ButtonContainer,
  Container,
  DeliveryContainer,
} from "../styled/OrderDetails";

import { requestData } from "../lib/rest_api_helper";

//Imported From Components Dir
import {
  InputContainer,
  Main,
  NoteContainer,
  SelectDeliveryMan,
} from "../styled/GlobalStyled";
import ProductControlCard from "../components/orders/ProductQtyControlCard";
import CrateDialog from "../components/utils/CrateDialog";
import { FilledButton } from "../components/utils/Buttons";
import BackToOrdersAppBar from "../components/utils/AppBarWithBackBtn";
import CustomerInfo from "../components/utils/CustomerInfo";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import OrderDetailsBox from "../components/orders/OrderDetailsBox";
import { roles } from "./Account";
import CouponProducts from "../components/orders/CouponProducts";
import CouponServices from "../components/orders/CouponServices";

const teams = ["picker", "qc", "packer", "delivery", "delivering"];
const status = {
  picker: "picking",
  qc: "qc",
  packer: "packing",
  delivery: "delivery",
  delivering: "delivering",
};
const nexStatus = {
  picker: "qc",
  qc: "packing",
  packer: "delivery",
  delivery: "delivering",
  delivering: "delivered",
};

const OrderDetails = () => {
  const { state: order } = useLocation();
  const [isError, setIsError] = useState(false);
  const [note, setNote] = useState("");
  const [noteFrom, setNoteFrom] = useState({ team: "", note: "" });
  const [emptyNote, setEmptyNote] = useState(false);
  const [deliveryMan, setDeliveryMan] = useState("");
  const [showCrateDialog, setShowCrateDialog] = useState(false);
  const [changedQty, setChangedQty] = useState({
    clickedProduct: null,
    data: [],
  });
  useEffect(() => {
    if (isError) {
      let timeout = setTimeout(() => setIsError(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [isError]);

  const { id: orderID } = useParams();

  const [params] = useSearchParams();
  const name = params.get("name");
  const manId = params.get("man_id");
  const phone = params.get("phone");

  const navigate = useNavigate();

  const {
    auth: { role },
  } = useSelector((state) => state);

  const handleQty = (clicked, data) => {
    if (data) {
      const previousData = changedQty.data;
      const newData = [
        ...previousData.filter((prev) => prev.sku !== data.sku),
        data,
      ];
      setChangedQty({ clickedProduct: clicked, data: newData });
    } else setChangedQty({ clickedProduct: clicked, data: changedQty.data });
  };

  useEffect(() => {
    if (name) {
      setDeliveryMan(name);
      // setShowCrateDialog(true);
    }
  }, [name]);
  useEffect(() => {
    if (order?.assigns) {
      const index = teams.indexOf(role);
      let team = roles[teams[index - 1]] + " team";
      if (index === 0) team = "Admin team";
      const note = order?.assigns?.find(
        (track) => track?.status === status[role]
      )?.note;
      setNoteFrom({ team, note });
    }
  }, [role, order]);
  if (!order) return "";

  const goToContactList = () => {
    navigate(`/contact-list?id=${orderID}&origin=orders/${orderID}`, {
      state: order,
    });
  };

  const handleAssign = async () => {
    if (note === "" && role === "picker") {
      setEmptyNote(true);
      setShowCrateDialog(false);
      return;
    }
    if (role === "delivery" && !manId) {
      setShowCrateDialog(false);
      return;
    }
    if (role === "delivering") {
      navigate(`/success?name`, { state: order });
      return;
    }

    let dataToPost = {
      assign_to: null,
      note,
      status: nexStatus[role],
      available_qty: changedQty.data,
    };

    if (manId) dataToPost.assign_to = manId;
    try {
      const { data } = await requestData.post(
        `orders/${order.id}/assign`,
        dataToPost
      );
      if (data.result) {
        navigate(
          `/success${
            manId
              ? "?man_id=" + manId + "&name=" + name + "&phone=" + phone
              : ""
          }`,
          { state: { ...order, note } }
        );
      }
      setShowCrateDialog(false);
    } catch (error) {
      setIsError(true);
    }
  };
  return (
    <Main sx={{ paddingBottom: "7rem" }}>
      <BackToOrdersAppBar url={"/orders"} />
      {role === "delivering" && (
        <Container>
          <OrderDetailsBox data={order} />
        </Container>
      )}
      {role === "delivery" && (
        <Container>
          <OrderDetailsBox data={order} />
        </Container>
      )}
      {["picker", "qc", "packer"].includes(role) && (
        <Container>
          <h5>Product List</h5>
          <Box
            sx={{
              marginTop: "1rem",
              borderBottom: "1px solid #eeeeee",
              paddingBottom: "1.5rem",
            }}
            display="grid"
            gridTemplateColumns="1fr"
            gap="1rem"
          >
            {order?.info?.length > 0 &&
              order.info.map((item, index) => (
                <ProductControlCard
                  key={index}
                  item={item}
                  controlQty={handleQty}
                  role={role}
                  index={index}
                  changedQty={changedQty}
                />
              ))}
          </Box>
        </Container>
      )}
      <Container>
        {order?.coupon_promo?.products?.length > 0 && (
          <CouponProducts products={order?.coupon_promo?.products} />
        )}
        {order?.coupon_promo?.services?.length > 0 && (
          <CouponServices services={order?.coupon_promo?.services} />
        )}
      </Container>
      {(role === "delivering" || role === "delivery") && (
        <Container>
          <CustomerInfo customer={order?.customer} date={order?.created_at} />
        </Container>
      )}

      {role === "delivery" && (
        <DeliveryContainer>
          <Divider sx={{ marginTop: "1.5rem" }} />
          <h6>Delivery Man</h6>
          <SelectDeliveryMan onClick={goToContactList}>
            {deliveryMan ? deliveryMan : "Select Delivery Man"}{" "}
            <ArrowDownIcon />
          </SelectDeliveryMan>
          <Divider sx={{ marginTop: "2.5rem" }} />
        </DeliveryContainer>
      )}

      <NoteContainer sx={{ marginTop: "1rem", marginBottom: "0.75rem" }}>
        <label style={{ textTransform: "capitalize" }}>
          Note From {noteFrom.team}
        </label>
        <p>{noteFrom.note}</p>
        <Divider sx={{ marginTop: "1rem" }} />
      </NoteContainer>

      {role !== "delivering" && (
        <Container sx={{ marginBottom: "1.5rem" }}>
          <InputContainer isEmpty={emptyNote}>
            <label>Add Notes</label>
            <textarea value={note} onChange={(e) => setNote(e.target.value)} />
          </InputContainer>
        </Container>
      )}

      <ButtonContainer>
        <FilledButton
          func={() => {
            setEmptyNote(false);
            setShowCrateDialog(true);
          }}
          text={
            role === "quality_control"
              ? "Confirm"
              : role === "picker"
              ? "Confirm Crate Box"
              : role === "delivery"
              ? "Assign Delivery Man"
              : role === "delivering"
              ? "Start Delivery"
              : "Done"
          }
        />
      </ButtonContainer>
      <CrateDialog
        openModal={showCrateDialog}
        handleClose={() => setShowCrateDialog(false)}
        role={role}
        deliMan={deliveryMan}
        func={handleAssign}
        isError={isError}
      />
    </Main>
  );
};

export default OrderDetails;
