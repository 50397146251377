import { Button, Card, styled } from "@mui/material";

export const StyledCard = styled(Card)`
  margin-inline: 1rem;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.gray_5};
  padding-block: 1.5rem;
  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    li {
      display: grid;
      grid-template-columns: 0.6fr 2px 1fr;
      place-items: center;
      text-align: right;
      gap: 1rem;
      span {
        width: 100%;
      }
      .data {
        text-align: left;
        color: ${({ theme }) => theme.palette.gray_6};
        font-weight: 600;
      }
      .role {
        text-transform: capitalize;
      }
    }
  }
`;

export const BtnContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const LogoutBtn = styled(Button)`
  border-radius: 10rem;
  text-transform: capitalize;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.gray_6};
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background-color: ${({ theme }) => theme.palette.gray_2};
  :hover {
    background-color: ${({ theme }) => theme.palette.gray_2};
  }
`;
