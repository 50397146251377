import { styled } from "@mui/material/styles";

export const InputContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  position: relative;
  label {
    color: ${({ theme }) => theme.palette.gray_9};
    font-size: 14px;
    font-weight: 600;
  }
  input,
  textarea {
    height: 3rem;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 24rem;
    border: none;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    padding-left: 0.5rem;
    background-color: ${({ theme }) => theme.palette.gray_2};
    animation: ${({ isEmpty }) => (isEmpty ? "empty 1s" : "")};
    @keyframes empty {
      0%,
      20%,
      40%,
      60%,
      80%,
      100% {
        border: solid 1px ${({ theme }) => theme.palette.red_1};
      }
      10%,
      30%,
      50%,
      70%,
      90% {
        border: none;
      }
    }
  }
  textarea {
    height: 6.25rem;
    margin-top: 0.25rem;
    max-width: 100%;
    padding-top: 0.5rem;
  }
`;

export const Main = styled("main")`
  color: ${({ theme }) => theme.palette.gray_9};
  padding: 4rem 0 3rem 0;
`;

export const SelectDeliveryMan = styled("button")`
  width: 100%;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.gray_6};
  border: solid 1px ${({ theme }) => theme.palette.gray_4};
  border-radius: 6px;
  background-color: white;
  font-weight: 600;
  height: 45px;
  max-width: 24rem;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif !important;
`;

export const EmptyMessage = styled("p")`
  color: ${({ theme }) => theme.palette.gray_6};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

export const NoteContainer = styled("div")`
  width: 100%;
  padding-inline: 1rem;
  label {
    color: ${({ theme }) => theme.palette.gray_9};
    font-size: 14px;
    font-weight: 600;
  }
  p {
    margin-top: 0.75rem;
  }
`;
