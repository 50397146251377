import { styled } from "@mui/material";
import React from "react";
import { ColorRing } from "react-loader-spinner";

const SpinnerContainer = styled("section")`
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  display: grid;
  place-items: center;
`;

const FallbackLoading = () => {
  return (
    <SpinnerContainer>
      <ColorRing
        visible={true}
        height="60"
        width="60"
        ariaLabel="blocks-loading"
        wrapperClass="blocks-wrapper"
        colors={["#75b206", "#75b206", "#75b206", "#75b206", "#75b206"]}
      />
    </SpinnerContainer>
  );
};

export default FallbackLoading;
