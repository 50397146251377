import React from "react";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material";

import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useNavigate } from "react-router";
import moment from "moment";
import { requestData } from "../../lib/rest_api_helper";

const StyledListItem = styled(ListItem)`
  margin-top: -1px;
  padding-block: 1rem;
  border-block: solid 2px
    ${({ theme, is_new }) =>
      is_new === "false" ? "transparent" : theme.palette.gray_2};
  border-left: solid 2px
    ${({ theme, is_new }) =>
      is_new === "false" ? theme.palette.primary.main : "white"};
  background-color: ${({ theme, is_new }) =>
    is_new === "false" ? theme.palette.primary.main + "1a" : "white"};
`;

const ItemText = styled("div")`
  color: ${({ theme }) => theme.palette.gray_6};
  .orderId {
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme, is_new }) =>
      is_new === "false" ? theme.palette.primary.main : theme.palette.gray_9};
  }
  .msg {
    font-size: 0.8rem;
  }
  .date {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.gray_5};
    gap: 0.3rem;
    padding-top: 1px;
  }
  .arrow {
    position: absolute;
    right: 0.5rem;
    top: 55%;
    transform: translateY(-50%);
  }
`;

export default function NotificationCard({ data, refetch }) {
  const navigate = useNavigate();
  const message = data?.description.split("#");
  const handleNotiRead = async () => {
    // try {
    //   await requestData.put(
    //     "back/operation/notifications/" + data?._id,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //       },
    //     }
    //   );
    //   refetch();
    // } catch (error) {
    //   console.log({ error });
    // }
  };
  return (
    <>
      <StyledListItem
        is_new={`${data.is_read}`}
        onClick={() => {
          handleNotiRead();
          navigate("/orders");
        }}
      >
        <ItemText is_new={`${data.is_read}`}>
          <p className="msg">{message[0]}</p>
          <p className="orderId">Order ID : {message[1]}</p>
          <p className="date">
            <TimeIcon sx={{ fontSize: "1rem" }} />
            {moment(data?.created_at).format("lll")}
          </p>
          <div className="arrow">
            <ArrowRightIcon sx={{ fontSize: "1.75rem" }} />
          </div>
        </ItemText>
      </StyledListItem>
    </>
  );
}
