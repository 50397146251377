import React, { useState } from "react";
import {
  ApplyButtons,
  Button,
  DateButtons,
  StyledCard,
  StyledDialog,
} from "../../styled/DatePicker";
import { FilledButton, OutlinedButton } from "./Buttons";

export default function DatePickerDialog({ control, modal }) {
  const [date, setDate] = useState("Any Time");
  const selectDate = (date) => {
    setDate(date);
  };
  return (
    <>
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => control(false, modal?.date)}
        open={modal?.show}
      >
        <StyledCard>
          <h5>Select Date</h5>
          <DateButtons>
            <Button
              onClick={() => selectDate("any_time")}
              active={`${date === "any_time"}`}
            >
              Any Time
            </Button>
            <Button
              onClick={() => selectDate("past_24_hours")}
              active={`${date === "past_24_hours"}`}
            >
              Past 24 Hours
            </Button>
            <Button
              onClick={() => selectDate("past_7_days")}
              active={`${date === "past_7_days"}`}
            >
              Past 7 Days
            </Button>
            <Button
              onClick={() => selectDate("past_14_days")}
              active={`${date === "past_14_days"}`}
            >
              Past 14 Days
            </Button>
            <Button
              onClick={() => selectDate("past_month")}
              active={`${date === "past_month"}`}
            >
              Past Month
            </Button>
          </DateButtons>
          <ApplyButtons>
            <OutlinedButton
              text="Cancel"
              func={() => control(false, modal?.date)}
            />
            <FilledButton
              text="Show Results"
              disabled={modal.date === date}
              func={() => control(false, date)}
            />
          </ApplyButtons>
        </StyledCard>
      </StyledDialog>
    </>
  );
}
