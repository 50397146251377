import React from "react";

import { Toolbar, AppBar, styled, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router";

import ArrowIcon from "@mui/icons-material/KeyboardBackspace";

const StyledBar = styled(AppBar)`
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  position: fixed;
  top: 0;
  left: 0;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

const BackToOrdersList = ({ url = -1, text }) => {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <StyledBar component="nav">
      <StyledToolbar>
        <IconButton
          onClick={() => navigate(url)}
          sx={{ marginLeft: "-0.5rem" }}
        >
          <ArrowIcon sx={{ color: "#555555" }} />
        </IconButton>
        {text ? <h4>{text}</h4> : <h4>Order ID : {params.id}</h4>}
      </StyledToolbar>
    </StyledBar>
  );
};

export default BackToOrdersList;
