import React from "react";

import BadgeIcon from "@mui/icons-material/Badge";
import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Card, Divider, ListItem, styled } from "@mui/material";
import moment from "moment/moment";

const StyledListItem = styled(ListItem)`
  align-items: flex-start;
  color: ${({ theme }) => theme.palette.gray_5};
  font-size: 0.8rem;
  gap: 0.5rem;
  padding-block: 1rem;
  ul {
    .data {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.gray_9};
      width: 100%;
    }
  }
`;

const CustomerInfo = ({ date, customer }) => {
  return (
    <Card sx={{ paddingTop: "1rem", marginBlock: "1rem" }}>
      <h4 style={{ marginLeft: "1rem" }}>Customer Information</h4>
      <Divider sx={{ marginInline: "1rem", marginTop: "0.75rem" }} />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <StyledListItem>
          <BadgeIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Customer</li>
            <li className="data">{customer?.name}</li>
          </ul>
        </StyledListItem>
        <StyledListItem>
          <TimeIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Date</li>
            <li className="data">{moment(date).format("ll")}</li>
          </ul>
        </StyledListItem>
        <StyledListItem sx={{ marginTop: "-1rem" }}>
          <PhoneIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Phone</li>
            <li className="data">{customer?.phone}</li>
          </ul>
        </StyledListItem>
        <StyledListItem sx={{ marginTop: "-1rem" }}>
          <EmailIcon sx={{ fontSize: "1.25rem" }} />
          <ul>
            <li>Email</li>
            <li className="data">{customer?.email}</li>
          </ul>
        </StyledListItem>
        <Box gridColumn="span 2" sx={{ marginTop: "-1rem" }}>
          <StyledListItem>
            <PlaceIcon sx={{ fontSize: "1.25rem" }} />
            <ul>
              <li>Place</li>
              <li className="data place">{customer?.address}</li>
            </ul>
          </StyledListItem>
        </Box>
      </Box>
    </Card>
  );
};

export default CustomerInfo;
