import React from "react";

import {
  Toolbar,
  AppBar,
  styled,
  Stack,
  Button,
  IconButton,
} from "@mui/material";

import CalendarIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";

const StyledBar = styled(AppBar)`
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  position: fixed;
  top: 0;
  left: 0;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  height: 2rem;
  border-radius: 100vw;
  padding-inline: 0.75rem;
  text-transform: capitalize;
  background-color: ${({ active, theme }) =>
    active === "true" ? theme.palette.gray_6 : "white"};
  color: ${({ active, theme }) =>
    active === "true" ? "white" : theme.palette.gray_5};
  border: solid 1px
    ${({ active, theme }) =>
      active === "true" ? theme.palette.gray_6 : "#d5d5d5"};
  :hover {
    background-color: ${({ active, theme }) =>
      active === "true" ? theme.palette.gray_6 : "white"};
  }
`;

const OrderNavBar = ({
  toggleDrawer,
  text,
  withAction = false,
  toggleDatePicker,
  selectedDate,
}) => {
  return (
    <StyledBar component="nav">
      <StyledToolbar>
        <h4>{text}</h4>
        {withAction && (
          <Stack direction="row" spacing={"0.25rem"} alignItems="center">
            <StyledButton
              onClick={() => toggleDatePicker(true, selectedDate)}
              startIcon={<CalendarIcon />}
              active={`${selectedDate !== "Any Time"}`}
            >
              {selectedDate?.split("_")?.join(" ")}
            </StyledButton>
            <IconButton onClick={toggleDrawer}>
              <SearchIcon sx={{ fontSize: "1.75rem" }} />
            </IconButton>
          </Stack>
        )}
      </StyledToolbar>
    </StyledBar>
  );
};

export default OrderNavBar;
