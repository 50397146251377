import React, { useState, useMemo } from "react";
import { Main } from "../styled/GlobalStyled";

import AppBar from "../components/utils/PrimaryAppBar";
import LogoutModal from "../components/Logout";

import LogoutIcon from "@mui/icons-material/Logout";
import { BtnContainer, LogoutBtn, StyledCard } from "../styled/Account";
import { useSelector } from "react-redux";

export const roles = {
  picker: "Picker",
  qc: "Quality Control",
  packer: "Packer",
  delivery: "Delivery Admin",
  delivering: "Delivery",
};

const Account = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const { role, name, email, phone } = useSelector((state) => state.auth);
  const handleClose = () => {
    setIsLoggingOut(false);
  };

  return (
    <Main>
      <AppBar text="Account" />
      <StyledCard>
        <ul>
          <li>
            <span>Name</span>
            <span>:</span>
            <span className="data">{name}</span>
          </li>
          <li>
            <span>Phone</span>
            <span>:</span>
            <span className="data">{phone}</span>
          </li>
          <li>
            <span>Role</span>
            <span>:</span>
            <span className="data role">{roles[role]}</span>
          </li>
          <li>
            <span>Email</span>
            <span>:</span>
            <span className="data">{email}</span>
          </li>
        </ul>
      </StyledCard>
      <BtnContainer>
        <LogoutBtn
          variant="contained"
          disableElevation
          startIcon={<LogoutIcon />}
          onClick={() => setIsLoggingOut(true)}
        >
          Logout
        </LogoutBtn>
      </BtnContainer>
      {isLoggingOut && (
        <LogoutModal openModal={isLoggingOut} handleClose={handleClose} />
      )}
    </Main>
  );
};

export default Account;
