import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { InputContainer, Main } from "../styled/GlobalStyled";
import BackToOrdersAppBar from "../components/utils/AppBarWithBackBtn";
import { FilledButton } from "../components/utils/Buttons";
import { ButtonContainer, Container } from "../styled/OrderDetails";
import { useSearchParams } from "react-router-dom";
import { requestData } from "../lib/rest_api_helper";

const WriteNotesPage = () => {
  const { state: order } = useLocation();
  const [note, setNote] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);

  const [params] = useSearchParams();

  const type = params.get("type");

  const navigate = useNavigate();

  const handleNote = async () => {
    if (note === "") {
      setIsEmpty(true);
      return;
    }
    let dataToPost = {
      assign_to: null,
      note,
      status: type === "customer" ? "delivered" : "failed",
    };

    try {
      await requestData.post(`orders/${order.id}/assign`, dataToPost);
      navigate("/activity-logs");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Main sx={{ paddingBottom: "7rem" }}>
      <BackToOrdersAppBar text="Write Notes" />

      <Container sx={{ marginBottom: "1.5rem" }}>
        <InputContainer isEmpty={isEmpty}>
          <label style={{ fontSize: "0.8rem" }}>Write notes</label>
          <textarea
            style={{ height: "140px" }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </InputContainer>
      </Container>

      <ButtonContainer>
        <FilledButton func={handleNote} text="Done" />
      </ButtonContainer>
    </Main>
  );
};

export default WriteNotesPage;
