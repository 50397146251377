import { Box, Button, ListItem, styled } from "@mui/material";

export const StyledBox = styled(Box)`
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 1rem 0rem 1rem;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  border: solid 1px
    ${({ is_done }) => (is_done === "true" ? "#75B20666" : "#EA5A0066")};
  border-radius: 6px;
  h4 {
    text-transform: capitalize;
    color: ${({ is_done, theme }) =>
      is_done === "true" ? theme.palette.primary.main : theme.palette.red_1};
    font-size: 0.8rem;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;
  padding-block: 1rem;
  height: 6rem;
  border-top: solid 1px ${({ theme }) => theme.palette.gray_2};

  border-bottom: solid 1px
    ${({ theme, showBorder }) =>
      showBorder === "false" ? theme.palette.gray_2 : "transparent"};
  width: 100%;
  figure {
    width: 60px;
    min-width: 60px;
    aspect-ratio: 1 / 1;
    margin-right: 1rem;
    border-radius: 4px;
    img {
      border-radius: 4px;
      aspect-ratio: 1 / 1;
      width: 60px;
    }
  }
`;

export const ProductsContainer = styled(Box)`
  width: 100%;
  max-height: ${({ expand }) => (expand === "true" ? "100%" : "18rem")};
  overflow: hidden;
`;

export const ItemText = styled("div")`
  width: 100%;
  padding-block: 10px;
  .name {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.gray_9};
    width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .qty {
    font-weight: 600;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.gray_6};
  }
  .scratch {
    text-decoration: line-through;
    margin-right: 0.5rem;
    text-decoration-color: red;
    text-decoration-thickness: 2px;
  }
  .variations {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: flex-start;
    margin-top: 3px;
    .variation {
      font-size: 0.6rem;
      padding-inline: 0.2rem;
      padding-block: 0.1rem;
      white-space: nowrap;
    }
    .color {
      width: 1.4rem;
      height: 1rem;
    }
  }
`;

export const SeeMore = styled(Button)`
  font-size: 0.8rem;
  width: 100%;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  padding-block: 0.75rem;
  color: ${({ isDone, theme }) =>
    isDone === "true" ? theme.palette.primary.main : theme.palette.red_1};
`;
