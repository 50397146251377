import { styled } from "@mui/material";

export const Container = styled("div")`
  width: 100%;
  padding-inline: 1rem;
  padding-top: 1rem;
  h5 {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.gray_9};
  }
`;

export const ButtonContainer = styled("div")`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  padding: 1.25rem 2rem;
  display: grid;
  place-items: center;
  gap: 0.5rem;
`;

export const DeliveryContainer = styled("div")`
  padding-inline: 1rem;
  padding-bottom: 1rem;
  h6 {
    color: ${({ theme }) => theme.palette.gray_9};
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;
