import { styled } from "@mui/material";
import React from "react";

const Container = styled("div")`
  width: 100%;
  padding-top: 1rem;
  h5 {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.gray_9};
  }
  .product {
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    border-radius: 0.25rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
  figure {
    margin-inline: 0.5rem;
    height: 60px;
    width: 60px;
    img {
      width: 60px;
      border-radius: 4px;
      aspect-ratio: 1 / 1;
    }
  }
`;

export default function CouponProducts({ products = [] }) {
  return (
    <Container>
      <h5>Coupon Products</h5>
      {products?.map((prod, ind) => (
        <div key={ind} className="product">
          <figure>
            <img src={prod.image} alt={prod.name} />
          </figure>
          {/* <ItemText> */}
          <h6 className="name">{prod.name}</h6>
          {/* <p className="qty">Requested Quantity : {item?.quantity}</p>
            <div className="variations">
              {item?.sku?.variations?.map((vari) => {
                if (vari?.variation?.type_value === "default") return "";
                const color = vari?.variation?.type_value?.startsWith("#")
                  ? vari?.variation?.type_value
                  : null;
                if (color)
                  return (
                    <p
                      key={vari.id}
                      className="color"
                      style={{ backgroundColor: color }}
                    ></p>
                  );
                else
                  return (
                    <p
                      key={vari.id}
                      className="variation"
                      style={{ backgroundColor: "#eee" }}
                    >
                      {vari?.variation.name}
                    </p>
                  );
              })} */}
          {/* </div> */}
          {/* </ItemText> */}
        </div>
      ))}
    </Container>
  );
}
