import React from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import { requestData } from "../lib/rest_api_helper";
import { Login } from "../lib/slices/AuthSlice";

import OliveGO from "../assets/images/Olivego.png";
import { FilledButton } from "../components/utils/Buttons";
import { InputContainer } from "../styled/GlobalStyled";
import { ErrorText, Main } from "../styled/Login";

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errorType, setErrorType] = useState({ type: "", msg: "" });
  const [wantsToRemember, setWantsToRemember] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (cred) => {
    try {
      if (cred.email === "") {
        setErrorType({ type: "empty_email", msg: "Enter your email address" });
        return;
      }
      if (cred.password === "") {
        setErrorType({ type: "empty_password", msg: "Enter password" });
      }

      const { data } = await requestData.post("login", {
        email: cred.email,
        password: cred.password,
      });
      if (data.result && data.result.token) return data.result;
      else
        setErrorType({ type: "unexpected", msg: "Unexpected error occurred" });
    } catch (error) {
      throw error;
    }
  };

  const { mutate, isLoading } = useMutation(handleLogin, {
    onSuccess: (data) => {
      const {
        token,
        user: {
          role_id,
          email,
          name,
          id,
          phone,
          role: { permissions },
        },
      } = data;
      if (wantsToRemember) {
        localStorage.setItem("remember", "true");
      }
      localStorage.setItem("access_token", token);
      dispatch(
        Login({ role: role_id, user_id: id, name, email, permissions, phone })
      );
      navigate("/orders");
    },
    onError: (err) => {
      if (err?.response?.status === 401)
        setErrorType({ type: "invalid", msg: "Invalid credentials" });
      if (err.code === "ERR_NETWORK")
        setErrorType({
          type: "network_error",
          msg: "Check your network connection",
        });
    },
  });

  return (
    <Main>
      <figure>
        <img src={OliveGO} alt="Olive GO" />
      </figure>
      <form>
        <InputContainer>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            autoComplete="off"
            value={credentials.email}
            onChange={(e) =>
              setCredentials((prev) => {
                return { ...prev, email: e.target.value };
              })
            }
          />
          {errorType.type === "empty_email" && (
            <ErrorText>{errorType.msg}</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={credentials.password}
            onChange={(e) =>
              setCredentials((prev) => {
                return { ...prev, password: e.target.value };
              })
            }
          />
          {errorType.type === "empty_password" && (
            <ErrorText>{errorType.msg}</ErrorText>
          )}
        </InputContainer>
        <div className="checkbox">
          <input
            type="checkbox"
            name="remember"
            id="remember"
            checked={wantsToRemember}
            onChange={(e) => setWantsToRemember(e.target.checked)}
          />
          <label htmlFor="remember" className="mb-[1px]">
            Remember Me
          </label>
        </div>
        <div style={{ position: "relative", marginBottom: "0" }}>
          <FilledButton
            text={isLoading ? "Verifying" : "Login"}
            type="button"
            disabled={isLoading}
            func={() => mutate(credentials)}
          />
          {!["empty_email", "empty_password"]?.includes(errorType.type) && (
            <ErrorText align={"center"} style={{ marginTop: "0.25rem" }}>
              {errorType.msg}
            </ErrorText>
          )}
        </div>
      </form>
    </Main>
  );
};

export default LoginPage;
