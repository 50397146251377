// import { Box, Button, ListItem, styled } from "@mui/material";
import React, { useState } from "react";

import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  ItemText,
  ProductsContainer,
  SeeMore,
  StyledBox,
  StyledListItem,
} from "../../styled/OrderDetailsBox";
import { Divider } from "@mui/material";
import { useEffect } from "react";
import { formatPrice } from "../../lib/priceFormat";
import { FreeProducts } from "../../styled/ProductQtyControlCard";

const OrderDetailsBox = ({ data = [] }) => {
  const [showAllProducts, setShowAllProducts] = useState(true);
  // const [total, setTotal] = useState(0);
  // useEffect(() => {
  //   checkData(data);
  // }, [data]);

  // function checkData(items = []) {
  //   let total = 0;
  //   items.forEach((item) => {
  //     if (item?.available_qty) {
  //       total += +item?.available_qty * +item?.price;
  //     } else total += item?.quantity * +item?.sku?.price;
  //   });
  //   setTotal(total);
  // }
  return (
    <StyledBox>
      <h4 style={{ marginBottom: "0.75rem" }}>Order Detail</h4>
      <Divider sx={{ marginTop: "0.75rem" }} />

      <ProductsContainer expand={`${showAllProducts}`}>
        {data?.info &&
          data?.info?.map((item, index) => (
            <OrderItem key={index} item={item} />
          ))}
      </ProductsContainer>
      <div className="total">
        <p>Delivery Fee</p> <h6>{formatPrice(data?.delivery_price)} Ks</h6>
      </div>
      <div className="total">
        <p>Total</p>{" "}
        <h6>
          {formatPrice(
            Number(data?.summary_price) + Number(data?.delivery_price)
          )}{" "}
          Ks
        </h6>
      </div>
      {/* {data?.length > 3 && (
        <SeeMore
          onClick={() => setShowAllProducts(!showAllProducts)}
          endIcon={
            showAllProducts ? (
              <ArrowUpIcon
                sx={{ transform: "scale(1.5)", marginLeft: "-1px" }}
              />
            ) : (
              <ArrowDownIcon
                sx={{ transform: "scale(1.5)", margin: "-3px 0 0 -3px" }}
              />
            )
          }
        >
          {showAllProducts ? "See Less" : "See More"}
        </SeeMore>
      )} */}
    </StyledBox>
  );
};

export default OrderDetailsBox;

function OrderItem({ item }) {
  return (
    <>
      <StyledListItem>
        <figure>
          <img
            src={item?.sku?.product?.media[0]?.thumbnail}
            alt={item?.sku?.product?.name}
          />
        </figure>
        <ItemText>
          <div className="name">
            <p className="item-name">{item.sku?.product?.name}</p>
            <p className="price">
              {formatPrice(
                Number(item?.sku?.price) *
                  Number(
                    item?.available_qty ? item?.available_qty : item.quantity
                  )
              )}{" "}
              MMK
            </p>
          </div>
          <p className="qty">
            Quantity :{" "}
            {item?.available_qty ? (
              <>
                <span className="scratch">{item?.quantity}</span>
                <span className>{item?.available_qty}</span>
              </>
            ) : (
              <span>{item?.quantity}</span>
            )}
          </p>
          <div className="variations">
            {item?.sku?.variations?.map((vari) => {
              if (vari?.variation?.type_value === "default") return "";
              const color = vari?.variation?.type_value?.startsWith("#")
                ? vari?.variation?.type_value
                : null;
              if (color)
                return (
                  <p
                    key={vari.id}
                    className="color"
                    style={{ backgroundColor: color }}
                  ></p>
                );
              else
                return (
                  <p
                    key={vari.id}
                    className="variation"
                    style={{ backgroundColor: "#eee" }}
                  >
                    {vari?.variation.name}
                  </p>
                );
            })}
          </div>
        </ItemText>
      </StyledListItem>
      {item?.get_y_products && item?.get_y_products?.length > 0 && (
        <FreeProducts>
          <h4>Gifts</h4>
          <div>
            {item?.get_y_products?.map((prod) => (
              <div key={prod.id} className="item">
                <figure>
                  <img src={prod?.image} alt={prod?.name} />
                </figure>
                <ItemText>
                  <h6 className="name">{prod?.name}</h6>
                  <div className="variations">
                    {prod?.variations?.map((vari) => {
                      if (vari?.type_value === "default") return "";
                      const color = vari?.type_value?.startsWith("#")
                        ? vari?.type_value
                        : null;
                      if (color)
                        return (
                          <p
                            key={vari.id}
                            className="color"
                            style={{ backgroundColor: color }}
                          ></p>
                        );
                      else
                        return (
                          <p
                            key={vari.id}
                            className="variation"
                            style={{ backgroundColor: "#eee" }}
                          >
                            {vari?.name}
                          </p>
                        );
                    })}
                  </div>
                </ItemText>
              </div>
            ))}
          </div>
        </FreeProducts>
      )}
    </>
  );
}
