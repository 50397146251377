import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Divider, styled } from "@mui/material";
import DoneProductList from "../components/orders/DoneProductList";
import CustomerInfo from "../components/utils/CustomerInfo";
import { FilledButton, OutlinedButton } from "../components/utils/Buttons";
import { ButtonContainer } from "../styled/OrderDetails";
import OrderDetailsBox from "../components/orders/OrderDetailsBox";
import DeliManInfo from "../components/utils/DeliManInfo";

import Delivered from "../assets/icons/delivered.png";
import Picker from "../assets/icons/picker.png";
import QC from "../assets/icons/qc.png";
import Packing from "../assets/icons/packing.png";
import Rtd from "../assets/icons/rtd.png";
import { useSearchParams } from "react-router-dom";
import CouponProducts from "../components/orders/CouponProducts";
import CouponServices from "../components/orders/CouponServices";

const SuccessContent = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  padding-top: 3rem;
  margin-bottom: 2rem;
  img {
    width: 100%;
    max-width: 70px;
    margin-bottom: 1.5rem;
  }
  .big {
    max-width: 100px;
  }
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 0.75rem;
  }
`;

export const Notes = styled("div")`
  margin-block: 1.5rem;
  h4 {
    font-size: 1rem;
  }
  p {
    margin-top: 0.3rem;
    color: ${({ theme }) => theme.palette.gray_6};
    font-size: 0.8rem;
    margin-bottom: 1.25rem;
    word-break: break-all;
    white-space: normal;
  }
`;

const content = {
  picker: {
    photo: Picker,
    success_text: "Successfully Created Crate Box!",
  },
  packer: {
    photo: Packing,
    success_text: "Successfully Packed Products!",
  },
  qc: {
    photo: QC,
    success_text: "Successfully Checked Quality!",
  },
  delivery: {
    photo: Rtd,
    success_text: "Successfully Assigned!",
  },
  delivering: {
    photo: Delivered,
    success_text: "Started delivering!",
  },
};

const successTexts = {
  picker: null,
  qc: "Checked quality of",
  packer: "Packed",
};

const Success = () => {
  const { state: order } = useLocation();
  const {
    auth: { role, name: userName, phone: userPhone },
  } = useSelector((state) => state);

  const [params] = useSearchParams();
  let name = params.get("name");
  const manId = params.get("man_id");
  let phone = params.get("phone");

  if (role === "delivering") {
    name = userName;
    phone = userPhone;
  }

  const navigate = useNavigate();

  const handleOrderSuccess = () => {
    if (role === "delivering") {
      navigate("/write-notes?type=customer", { state: order });
      return;
    }
    navigate(role === "delivery" ? "/orders" : "/activity-logs");
    return;
  };
  return (
    <main
      style={{
        paddingInline: "1rem",
        paddingBottom: role === "delivering" ? "10rem" : "6rem",
      }}
    >
      <SuccessContent>
        <img
          className={role === "delivering" ? "big" : ""}
          src={content[role]?.photo}
          alt="Success"
        />
        <h4>{content[role]?.success_text}</h4>
      </SuccessContent>
      {["picker", "qc", "packer"].includes(role) && (
        <DoneProductList
          data={order?.info}
          done={true}
          text={successTexts[role]}
        />
      )}

      {(role === "delivering" || role === "delivery") && (
        <>
          <DeliManInfo data={{ name, phone }} />
          <OrderDetailsBox data={order} />
        </>
      )}
      {order?.coupon_promo?.products?.length > 0 && (
        <CouponProducts products={order?.coupon_promo?.products} />
      )}
      {order?.coupon_promo?.services?.length > 0 && (
        <CouponServices services={order?.coupon_promo?.services} />
      )}

      {role !== "delivering" && (
        <Notes>
          <h4>Additional Notes</h4>
          <p>{order?.note}</p>
          <Divider />
        </Notes>
      )}
      <CustomerInfo customer={order?.customer} date={order?.created_at} />
      <ButtonContainer>
        <FilledButton
          func={handleOrderSuccess}
          text={role === "delivering" ? "Delivered" : "Finished"}
        />
        {role === "delivering" && (
          <OutlinedButton
            text="Uncomplete"
            func={() =>
              navigate("/write-notes?type=uncomplete-delivery", {
                state: order,
              })
            }
          />
        )}
      </ButtonContainer>
    </main>
  );
};

export default Success;
