import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    SetNotiCount: (state, action) => {
      console.log("called >>>", action.payload);
      state.count += action.payload.count;
    },
    ResetNotiCount: (state) => {
      state.count = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetNotiCount, ResetNotiCount } = NotificationsSlice.actions;

export default NotificationsSlice.reducer;
