import { styled } from "@mui/material";

export const DividedByAlphabet = styled("div")`
  font-size: 1rem;
  h6 {
    font-size: 1rem;
    margin-top: 1.5rem;
    color: ${({ theme }) => theme.palette.gray_5};
    text-transform: capitalize;
  }
`;

export const NameContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .link {
    color: ${({ theme }) => theme.palette.gray_6};
    text-transform: capitalize;
    font-weight: 600;
    text-decoration: none;
    .highlight {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

export const NameNavigator = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 1rem;
  .alphabet {
    text-transform: capitalize;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
`;

export const SearchBar = styled("div")`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.gray_2};
  height: 3.5rem;
  color: ${({ theme }) => theme.palette.gray_5};
  font-size: 16px;
  gap: 0.5rem;
`;

export const StyledTextField = styled("input")`
  font-size: 16px;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  ::placeholder {
    color: ${({ theme }) => theme.palette.gray_5};
  }
`;

export const ErrorText = styled("p")`
  color: ${({ theme }) => theme.palette.red_1};
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
