import React from "react";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { styled } from "@mui/material";

import TimeIcon from "@mui/icons-material/AccessTimeFilled";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Icon from "../../assets/icons/AL.png";
import { useNavigate } from "react-router";
import { useMemo } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { SetLogDetail } from "../../lib/slices/ActivityLogSlice";

const ItemText = styled("div")`
  color: ${({ theme }) => theme.palette.gray_9};
  .orderId {
    font-weight: 700;
    font-size: 16px;
  }
  .msg {
    font-size: 0.8rem;
    span {
      color: ${({ theme }) => theme.palette.primary.main};
      font-weight: 600;
    }
  }
  .date {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.gray_5};
    gap: 0.25rem;
  }
  .arrow {
    position: absolute;
    right: 0.5rem;
    top: 55%;
    transform: translateY(-50%);
  }
`;

const StyledAvatar = styled("figure")`
  background-color: ${({ theme }) => theme.palette.primary.main};
  object-fit: contain;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: grid;
  place-items: center;
  img {
    width: 100%;
    max-width: 1rem;
    object-fit: contain;
  }
`;

export default function LogCard({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleActivityLog = () => {
    dispatch(SetLogDetail({ data }));
    navigate("/activity-logs/" + data?.order?.order_number, {
      state: data?.order,
    });
  };

  return (
    <>
      <ListItem sx={{ marginBlock: "0.25rem" }} onClick={handleActivityLog}>
        <ListItemAvatar>
          <StyledAvatar>
            <img alt="" src={Icon} />
          </StyledAvatar>
        </ListItemAvatar>
        <ItemText>
          <p className="orderId">Order ID : {data?.order?.order_number}</p>
          <p className="msg">{data?.name}</p>
          <p className="date">
            <TimeIcon sx={{ fontSize: "1rem" }} />
            {moment(data?.created_at).format("lll")}
          </p>
          <div className="arrow">
            <ArrowRightIcon sx={{ fontSize: "1.75rem" }} />
          </div>
        </ItemText>
      </ListItem>
      <Divider variant="inset" sx={{ marginRight: "1rem" }} component="li" />
    </>
  );
}
